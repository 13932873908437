#submitButton {
    visibility: hidden;
}

@font-face {
    font-family: "Georgia Bold Italic";
    src: local("Georgia Bold Italic"),
    url("./static/Tipografía/Georgia/georgia bold italic.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Georgia Bold";
    src: local("Georgia Bold"),
    url("./static/Tipografía/Georgia/georgia\ bold.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Georgia Italic";
    src: local("Georgia Italic"),
    url("./static/Tipografía/Georgia/georgia\ italic.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Georgia";
    src: local("Georgia"),
    url("./static/Tipografía/Georgia/georgia.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Verdana";
    src: local("Verdana"),
    url("./static/Tipografía/Verdana/verdana.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Verdana Italic";
    src: local("Verdana Italic"),
    url("./static/Tipografía/Verdana/verdana-italica.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Verdana Bold";
    src: local("Verdana Bold"),
    url("./static/Tipografía/Verdana/verdana-negrita.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Verdana Bold Italic";
    src: local("Verdana Bold Italic"),
    url("./static/Tipografía/Verdana/verdana-negrita-cursiva.ttf") format("truetype");
    font-weight: normal;
}

.placeholder1 {
    border: 2px solid #A68A30;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 0px;
    padding-right: 10px;
    border-radius: 6px;
    color: #FFFFFF;
    min-width: 200px;
    text-align: left;
    font-size: 16px;
    font-family: Verdana;
    height: 30px;
  }

  .placeholder2 {
    border: 2px solid #A68A30;
    padding: 0px 10px;
    padding-top: 10px;
    padding-bottom: 0px;
    border-radius: 6px;
    color: #f3f3f3;
    /*min-width: 90vw !important;*/
    text-align: left;
    font-size: 0.9em;
    margin-left: 1%;
    margin-right: 2%;
    height: 30px;
    font-size: 14px;
    font-family: Verdana;
  }

  .placeholder3 {
    border: 2px solid #182C20;
    padding: 7px 10px;
    border-radius: 6px;
    color: #DFDFDF;
    /*width: 350px;*/
    text-align: left;
    /*height: 30px;*/
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: Verdana;
    font-size: 14px;
  }

  .placeholderWrap {
    display: flex;
  }
  
  .placeholderWrap .placeholder:last-child {
    margin-left: 10px;
  }

  .date-picker-app-wrapper {
    width: 100%;
  }

  .calendar {
      z-index: 1000;
  }

  .alice-carousel__stage-item.__target div.item img{
    border: 3px solid #F7D00E;
  }


  